import React from 'react'
import './complited_project.css'
const Comproject = () => {
  return  (
        <div className='profil_myproject_comproject'>

            <div class ="profil_myproject_upper">

                <p className='profil_myproject_comproject_info'>Предыдущий проект</p>
                <p className='profil_myproject_comproject_ist'>Источник проeкта</p>
                <p className='profil_myproject_comproject_date'>до дд.мм.гггг</p><br/>
            
            </div>




            <div className='profil_myproject_comproject_cont'>
                <div className='profil_myproject_comproject_img'>
                    <svg class ="profil_myproject_comproject_imgSize" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/outline/calendar">
                            <g id="calendar">
                                <path id="Vector" d="M9.33301 6.70833C8.85467 6.70833 8.45801 6.31167 8.45801 5.83333V2.33333C8.45801 1.855 8.85467 1.45833 9.33301 1.45833C9.81134 1.45833 10.208 1.855 10.208 2.33333V5.83333C10.208 6.31167 9.81134 6.70833 9.33301 6.70833Z" fill="white"/>
                                <path id="Vector_2" d="M18.667 6.70833C18.1887 6.70833 17.792 6.31167 17.792 5.83333V2.33333C17.792 1.855 18.1887 1.45833 18.667 1.45833C19.1453 1.45833 19.542 1.855 19.542 2.33333V5.83333C19.542 6.31167 19.1453 6.70833 18.667 6.70833Z" fill="white"/>
                                <path id="Vector_3" d="M9.91667 16.9167C9.765 16.9167 9.61333 16.8817 9.47333 16.8234C9.32167 16.765 9.205 16.6833 9.08833 16.5783C8.87833 16.3567 8.75 16.065 8.75 15.75C8.75 15.5983 8.785 15.4467 8.84334 15.3067C8.90167 15.1667 8.98333 15.0384 9.08833 14.9217C9.205 14.8167 9.32167 14.735 9.47333 14.6767C9.89333 14.5017 10.4183 14.595 10.745 14.9217C10.955 15.1434 11.0833 15.4467 11.0833 15.75C11.0833 15.82 11.0717 15.9017 11.06 15.9834C11.0483 16.0534 11.025 16.1233 10.99 16.1933C10.9667 16.2633 10.9317 16.3333 10.885 16.4033C10.85 16.4617 10.7917 16.52 10.745 16.5783C10.5233 16.7883 10.22 16.9167 9.91667 16.9167Z" fill="white"/>
                                <path id="Vector_4" d="M13.9997 16.9167C13.848 16.9167 13.6963 16.8817 13.5563 16.8233C13.4047 16.765 13.288 16.6833 13.1713 16.5783C12.9613 16.3567 12.833 16.065 12.833 15.75C12.833 15.5983 12.868 15.4467 12.9263 15.3067C12.9847 15.1667 13.0663 15.0383 13.1713 14.9217C13.288 14.8167 13.4047 14.735 13.5563 14.6766C13.9763 14.49 14.5013 14.595 14.828 14.9217C15.038 15.1433 15.1663 15.4467 15.1663 15.75C15.1663 15.82 15.1547 15.9017 15.143 15.9833C15.1313 16.0533 15.108 16.1233 15.073 16.1933C15.0497 16.2633 15.0147 16.3333 14.968 16.4033C14.933 16.4617 14.8747 16.52 14.828 16.5783C14.6063 16.7883 14.303 16.9167 13.9997 16.9167Z" fill="white"/>
                                <path id="Vector_5" d="M18.0837 16.9167C17.932 16.9167 17.7803 16.8817 17.6403 16.8233C17.4887 16.765 17.372 16.6833 17.2553 16.5783C17.2087 16.52 17.162 16.4617 17.1153 16.4033C17.0687 16.3333 17.0337 16.2633 17.0103 16.1933C16.9753 16.1233 16.952 16.0533 16.9403 15.9833C16.9287 15.9017 16.917 15.82 16.917 15.75C16.917 15.4467 17.0453 15.1433 17.2553 14.9217C17.372 14.8167 17.4887 14.735 17.6403 14.6766C18.072 14.49 18.5853 14.595 18.912 14.9217C19.122 15.1433 19.2503 15.4467 19.2503 15.75C19.2503 15.82 19.2387 15.9017 19.227 15.9833C19.2153 16.0533 19.192 16.1233 19.157 16.1933C19.1337 16.2633 19.0987 16.3333 19.052 16.4033C19.017 16.4617 18.9587 16.52 18.912 16.5783C18.6903 16.7883 18.387 16.9167 18.0837 16.9167Z" fill="white"/>
                                <path id="Vector_6" d="M9.91667 21C9.765 21 9.61333 20.965 9.47333 20.9067C9.33333 20.8484 9.205 20.7667 9.08833 20.6617C8.87833 20.44 8.75 20.1367 8.75 19.8333C8.75 19.6817 8.785 19.53 8.84334 19.39C8.90167 19.2383 8.98333 19.11 9.08833 19.005C9.52 18.5733 10.3133 18.5733 10.745 19.005C10.955 19.2267 11.0833 19.53 11.0833 19.8333C11.0833 20.1367 10.955 20.44 10.745 20.6617C10.5233 20.8717 10.22 21 9.91667 21Z" fill="white"/>
                                <path id="Vector_7" d="M13.9997 21C13.6963 21 13.393 20.8717 13.1713 20.6617C12.9613 20.44 12.833 20.1367 12.833 19.8333C12.833 19.6817 12.868 19.53 12.9263 19.39C12.9847 19.2383 13.0663 19.11 13.1713 19.005C13.603 18.5733 14.3963 18.5733 14.828 19.005C14.933 19.11 15.0147 19.2383 15.073 19.39C15.1313 19.53 15.1663 19.6817 15.1663 19.8333C15.1663 20.1367 15.038 20.44 14.828 20.6617C14.6063 20.8717 14.303 21 13.9997 21Z" fill="white"/>
                                <path id="Vector_8" d="M18.0837 21C17.7803 21 17.477 20.8717 17.2553 20.6617C17.1503 20.5567 17.0687 20.4284 17.0103 20.2767C16.952 20.1367 16.917 19.985 16.917 19.8334C16.917 19.6817 16.952 19.53 17.0103 19.39C17.0687 19.2384 17.1503 19.11 17.2553 19.005C17.5237 18.7367 17.932 18.6084 18.3053 18.69C18.387 18.7017 18.457 18.725 18.527 18.76C18.597 18.7834 18.667 18.8184 18.737 18.8651C18.7953 18.9001 18.8537 18.9584 18.912 19.005C19.122 19.2267 19.2503 19.53 19.2503 19.8334C19.2503 20.1367 19.122 20.44 18.912 20.6617C18.6903 20.8717 18.387 21 18.0837 21Z" fill="white"/>
                                <path id="Vector_9" d="M23.9163 11.48H4.08301C3.60467 11.48 3.20801 11.0833 3.20801 10.605C3.20801 10.1266 3.60467 9.72996 4.08301 9.72996H23.9163C24.3947 9.72996 24.7913 10.1266 24.7913 10.605C24.7913 11.0833 24.3947 11.48 23.9163 11.48Z" fill="white"/>
                                <path id="Vector_10" d="M18.6667 26.5417H9.33333C5.075 26.5417 2.625 24.0917 2.625 19.8333V9.91667C2.625 5.65833 5.075 3.20833 9.33333 3.20833H18.6667C22.925 3.20833 25.375 5.65833 25.375 9.91667V19.8333C25.375 24.0917 22.925 26.5417 18.6667 26.5417ZM9.33333 4.95833C5.99667 4.95833 4.375 6.58 4.375 9.91667V19.8333C4.375 23.17 5.99667 24.7917 9.33333 24.7917H18.6667C22.0033 24.7917 23.625 23.17 23.625 19.8333V9.91667C23.625 6.58 22.0033 4.95833 18.6667 4.95833H9.33333Z" fill="white"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <p className='profil_myproject_comproject_n'>n месяцев</p>
            </div>

        
            <div className='profil_myproject_comproject_cont'>
                    <div className='profil_myproject_comproject_img'>
                        <svg class ="profil_myproject_comproject_imgSize" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="vuesax/outline/document">
                                <g id="document">
                                    <path id="Vector" d="M17.4997 26.5417H10.4997C4.16467 26.5417 1.45801 23.835 1.45801 17.5V10.5C1.45801 4.165 4.16467 1.45834 10.4997 1.45834H16.333C16.8113 1.45834 17.208 1.855 17.208 2.33334C17.208 2.81167 16.8113 3.20834 16.333 3.20834H10.4997C5.12134 3.20834 3.20801 5.12167 3.20801 10.5V17.5C3.20801 22.8783 5.12134 24.7917 10.4997 24.7917H17.4997C22.878 24.7917 24.7913 22.8783 24.7913 17.5V11.6667C24.7913 11.1883 25.188 10.7917 25.6663 10.7917C26.1447 10.7917 26.5413 11.1883 26.5413 11.6667V17.5C26.5413 23.835 23.8347 26.5417 17.4997 26.5417Z" fill="white"/>
                                    <path id="Vector_2" d="M25.6663 12.5417H20.9997C17.0097 12.5417 15.458 10.99 15.458 7V2.33333C15.458 1.98333 15.668 1.65666 15.9947 1.52833C16.3213 1.38833 16.6947 1.47 16.9513 1.715L26.2847 11.0483C26.5297 11.2933 26.6113 11.6783 26.4713 12.005C26.3313 12.3317 26.0163 12.5417 25.6663 12.5417ZM17.208 4.445V7C17.208 10.01 17.9897 10.7917 20.9997 10.7917H23.5547L17.208 4.445Z" fill="white"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p className='profil_myproject_comproject_n'>тип проекта</p>
            </div>


            

            <div className='profil_myproject_comproject_conthesh'>
                <p className='profil_myproject_comproject_hesh'>#хештег</p>
                <p className='profil_myproject_comproject_hesh'>#хештег</p>
            </div>

        </div>
    )
}

export default Comproject;
